<template>
  <div id="app" class="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
    <h1 class="text-3xl font-bold mb-6">Pizza Calculator</h1>
    
    <div class="space-y-4 w-full max-w-md">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="numberOfBalls" class="text-base lg:text-lg">Pizza Balls</label>
          <input v-model.number="numberOfBalls" id="numberOfBalls" type="number" class="p-2 border rounded text-center font-bold w-full"/>
        </div>
        <div class="flex flex-col">
          <label for="ballWeight" class="text-base lg:text-lg">Ball Weight</label>
          <input v-model.number="ballWeight" id="ballWeight" type="number" class="p-2 border rounded text-center font-bold w-full"/>
        </div>
      </div>
      
      <div class="flex flex-col">
        <label for="waterPercentage" class="text-base lg:text-lg">Water</label>
        <div class="relative">
          <input v-model.number="waterPercentage" id="waterPercentage" type="number" step="1" class="p-2 border rounded text-center font-bold w-full"/>
          <span class="absolute right-3 top-2">%</span>
        </div>
      </div>
      
      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="temp" class="text-base lg:text-lg">Temperature</label>
          <input v-model.number="temp" id="temp" type="number" step="0.5" class="p-2 border rounded text-center font-bold w-full "/>
        </div>
        <div class="flex flex-col">
          <label for="hours" class="text-base lg:text-lg">Fermentation Hours</label>
          <input v-model.number="hours" id="hours" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="oilPercentage" class="text-base lg:text-lg">Oil</label>
          <div class="relative">
            <input v-model.number="oilPercentage" id="oilPercentage" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
        <div class="flex flex-col">
          <label for="saltPercentage" class="text-base lg:text-lg">Salt</label>
          <div class="relative">
            <input v-model.number="saltPercentage" id="saltPercentage" value="2" step="0.1" type="number" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <div class="flex flex-col">
          <label for="sugarPercentage" class="text-base lg:text-lg">Sugar</label>
          <div class="relative">
            <input v-model.number="sugarPercentage" id="sugarPercentage" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
        <div class="flex flex-col">
          <label for="maltPercentage" class="text-base lg:text-lg">Malt</label>
          <div class="relative">
            <input v-model.number="maltPercentage" id="maltPercentage" type="number" step="0.1" class="p-2 border rounded text-center font-bold w-full"/>
            <span class="absolute right-3 top-2">%</span>
          </div>
        </div>
      </div>
      
      <button 
        @click="calculateIngredients" 
        class="w-full bg-blue-500 text-white p-2 rounded mt-4 disabled:opacity-50"
        :disabled="isCalculating"
      >
        <span v-if="isCalculating">Calculating...</span>
        <span v-else>Calculate Ingredients</span>
      </button>
    </div>

    <div v-if="ingredientResults" class="mt-6 w-full max-w-md bg-white p-4 rounded shadow">
      <h2 class="text-2xl font-semibold mb-4">Ingredient Results</h2>
      <div class="flex justify-between"><span>Flour:</span> <span>{{ ingredientResults.flour.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Water:</span> <span>{{ ingredientResults.water.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Oil:</span> <span>{{ ingredientResults.oil.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Salt:</span> <span>{{ ingredientResults.salt.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Sugar:</span> <span>{{ ingredientResults.sugar.toFixed(2) }}g</span></div>
      <div class="flex justify-between"><span>Malt:</span> <span>{{ ingredientResults.malt.toFixed(2) }}g</span></div>
      <div class="flex justify-between">
        <span>Yeast:</span> 
        <span>{{ (ingredientResults.flour * yeastPercentage / 100).toFixed(3) }}g ({{ yeastPercentage.toFixed(3) }}%)</span>
      </div>
    </div>

    <div class="mt-6 w-full max-w-md">
      <div class="flex flex-col space-y-4">
        <div class="flex flex-col">
          <label for="recipeName" class="text-base lg:text-lg">Recipe Name:</label>
          <input 
            v-model="recipeName" 
            id="recipeName" 
            type="text" 
            class="p-2 border rounded text-center font-bold"
            :class="{'border-red-500': recipeNameError}"
          />
          <span v-if="recipeNameError" class="text-red-500 text-sm mt-1">{{ recipeNameError }}</span>
        </div>
        
        <div class="flex space-x-2">
          <button 
            @click="saveRecipe" 
            class="flex-1 bg-green-500 text-white p-2 rounded disabled:opacity-50 flex items-center justify-center"
            :disabled="isSaving"
          >
            <svg v-if="isSaving" class="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span>{{ isSaving ? 'Saving...' : 'Save Recipe' }}</span>
          </button>
          <button 
            @click="resetForm" 
            class="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            title="Reset form (Ctrl+R)"
          >
            ↺
          </button>
        </div>
        
        <button 
          @click="loadRecipes" 
          class="w-full bg-gray-500 text-white p-2 rounded mt-2 disabled:opacity-50 flex items-center justify-center"
          :disabled="isLoading"
        >
          <svg v-if="isLoading" class="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4" fill="none"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span>{{ isLoading ? 'Loading...' : 'Load Recipes' }}</span>
        </button>
      </div>
    </div>

    <ul class="mt-6 w-full max-w-md">
      <li v-for="recipe in recipes" :key="recipe.name" 
        class="bg-white p-4 rounded shadow mb-2 flex justify-between items-center"
        :class="{'animate-success': recipe.name === lastSavedRecipe}"
      >
        {{ recipe.name }}
        <div>
          <button 
            @click="confirmDelete(recipe.name)" 
            class="bg-red-500 text-white p-2 rounded mr-2 disabled:opacity-50"
            :disabled="isDeleting === recipe.name"
          >
            <span v-if="isDeleting === recipe.name">Deleting...</span>
            <span v-else>Delete</span>
          </button>
          <button @click="loadRecipe(recipe.recipe)" class="bg-blue-500 text-white p-2 rounded">Load</button>
        </div>
      </li>
    </ul>

    <!-- Modals -->
    <div v-if="showDeleteModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div class="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
        <h3 class="text-lg font-semibold mb-4">Confirm Delete</h3>
        <p>Are you sure you want to delete "{{ recipeToDelete }}"?</p>
        <div class="mt-6 flex justify-end space-x-3">
          <button 
            @click="showDeleteModal = false" 
            class="px-4 py-2 bg-gray-200 rounded"
          >
            Cancel
          </button>
          <button 
            @click="executeDelete" 
            class="px-4 py-2 bg-red-500 text-white rounded"
          >
            Delete
          </button>
        </div>
      </div>
    </div>

    <div v-if="notification" class="fixed bottom-4 right-4 bg-gray-800 text-white px-6 py-3 rounded shadow-lg">
      {{ notification }}
    </div>
  </div>
</template>

<script>
import  yeastData  from './data.json';
import { getYeastPercentage } from './utils';

export default {
  data() {
    return {
      temp: 18,
      hours: 6,
      yeastPercentage: null,
      numberOfBalls: 1,
      ballWeight: 300,
      waterPercentage: 60,
      oilPercentage: 1,
      saltPercentage: 2,
      sugarPercentage: 1,
      maltPercentage: 1,
      ingredientResults: null,
      recipeName: '',
      recipes: [],
      isCalculating: false,
      isSaving: false,
      isLoading: false,
      isDeleting: null,
      showDeleteModal: false,
      recipeToDelete: null,
      notification: null,
      recipeNameError: '',
      lastSavedRecipe: null,
      defaultValues: {
        temp: 18,
        hours: 6,
        numberOfBalls: 1,
        ballWeight: 300,
        waterPercentage: 60,
        oilPercentage: 1,
        saltPercentage: 2,
        sugarPercentage: 1,
        maltPercentage: 1,
      },
    };
  },
  methods: {
    showNotification(message, duration = 3000) {
      this.notification = message;
      setTimeout(() => {
        this.notification = null;
      }, duration);
    },
    async calculateIngredients() {
      this.isCalculating = true;
      try {
        console.log("Calculating yeast percentage and ingredients...");  // Debugging log
        // Calculate yeast percentage
        if (this.temp !== null && this.hours !== null) {
          this.yeastPercentage = getYeastPercentage(this.temp, this.hours, yeastData);
        } else {
          this.yeastPercentage = null;
        }

        // Calculate ingredients
        const totalDoughWeight = this.numberOfBalls * this.ballWeight;
        const flourWeight = totalDoughWeight / (1 + this.waterPercentage / 100 + this.oilPercentage / 100 + this.saltPercentage / 100 + this.sugarPercentage / 100 + this.maltPercentage / 100);

        this.ingredientResults = {
          flour: flourWeight,
          water: flourWeight * (this.waterPercentage / 100),
          oil: flourWeight * (this.oilPercentage / 100),
          salt: flourWeight * (this.saltPercentage / 100),
          sugar: flourWeight * (this.sugarPercentage / 100),
          malt: flourWeight * (this.maltPercentage / 100),
        };
      } finally {
        this.isCalculating = false;
      }
    },
    async saveRecipe() {
      if (!this.validateForm()) return;
      
      if (this.temp === null || this.hours === null || this.ingredientResults === null) {
        alert("Please fill out all required fields and calculate ingredients before saving.");
        return;
      }

      this.isSaving = true;
      const recipe = {
        temp: this.temp,
        hours: this.hours,
        yeastPercentage: this.yeastPercentage,
        numberOfBalls: this.numberOfBalls,
        ballWeight: this.ballWeight,
        waterPercentage: this.waterPercentage,
        oilPercentage: this.oilPercentage,
        saltPercentage: this.saltPercentage,
        sugarPercentage: this.sugarPercentage,
        maltPercentage: this.maltPercentage,
        ingredientResults: this.ingredientResults,
      };

      try {
        const response = await fetch(`/api/save`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: this.recipeName, recipe }),
        });

        if (!response.ok) {
          throw new Error('Failed to save the recipe.');
        }

        await response.json();
        this.showNotification("Recipe saved successfully!");
        this.lastSavedRecipe = this.recipeName;
        setTimeout(() => {
          this.lastSavedRecipe = null;
        }, 1000);
      } catch (error) {
        console.error('Error saving recipe:', error);
        this.showNotification("Error saving recipe. Please try again.");
      } finally {
        this.isSaving = false;
      }
    },
    async loadRecipes() {
      this.isLoading = true;
      try {
        console.log('Loading recipes...'); // Debugging log
        const response = await fetch(`/api/load`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to load recipes.');
        }

        const data = await response.json();
        console.log('Loaded recipes:', data.data); // Debugging log
        this.recipes = data.data; // Assuming the API response contains an array of recipes under 'data'
      } catch (error) {
        console.error('Error loading recipes:', error);
        this.showNotification("Error loading recipes. Please try again.");
      } finally {
        this.isLoading = false;
      }
    },
    confirmDelete(name) {
      this.recipeToDelete = name;
      this.showDeleteModal = true;
    },
    async executeDelete() {
      this.showDeleteModal = false;
      this.isDeleting = this.recipeToDelete;
      try {
        await this.deleteRecipe(this.recipeToDelete);
        this.showNotification("Recipe deleted successfully!");
      } finally {
        this.isDeleting = null;
        this.recipeToDelete = null;
      }
    },
    async deleteRecipe(name) {
      if (!name) {
        alert("Please provide a name for the recipe to delete.");
        return;
      }
      try {
        const response = await fetch(`/api/delete?name=${encodeURIComponent(name)}`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          throw new Error('Failed to delete the recipe.');
        }
        const message = await response.json();
        this.showNotification(message.message);
        // Reload recipes after deletion
        this.loadRecipes();
      } catch (error) {
        console.error('Error deleting recipe:', error);
        this.showNotification(error.message);
      }
    },
    loadRecipe(recipe) {
      console.log('Loading recipe:', recipe); // Debugging log
      this.temp = recipe.temp;
      this.hours = recipe.hours;
      this.yeastPercentage = recipe.yeastPercentage;
      this.numberOfBalls = recipe.numberOfBalls;
      this.ballWeight = recipe.ballWeight;
      this.waterPercentage = recipe.waterPercentage;
      this.oilPercentage = recipe.oilPercentage;
      this.saltPercentage = recipe.saltPercentage;
      this.sugarPercentage = recipe.sugarPercentage;
      this.maltPercentage = recipe.maltPercentage;
      this.ingredientResults = recipe.ingredientResults;

   
    },
    resetForm() {
      Object.keys(this.defaultValues).forEach(key => {
        this[key] = this.defaultValues[key];
      });
      this.recipeName = '';
      this.ingredientResults = null;
      this.showNotification('Form reset to defaults');
    },
    validateForm() {
      this.recipeNameError = '';
      if (!this.recipeName) {
        this.recipeNameError = 'Recipe name is required';
        return false;
      }
      if (this.recipes.some(r => r.name === this.recipeName)) {
        this.recipeNameError = 'Recipe name already exists';
        return false;
      }
      return true;
    },
    setupKeyboardShortcuts() {
      document.addEventListener('keydown', (e) => {
        if (e.ctrlKey && e.key === 'r') {
          e.preventDefault();
          this.resetForm();
        }
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          this.saveRecipe();
        }
        if (e.ctrlKey && e.key === 'l') {
          e.preventDefault();
          this.loadRecipes();
        }
      });
    },
  },
  mounted() {
    this.loadRecipes();
    this.setupKeyboardShortcuts();
  },
  beforeUnmount() {
    // Clean up event listeners
    document.removeEventListener('keydown', this.setupKeyboardShortcuts);
  },
};
</script>

<style scoped>
  @import "tailwindcss/tailwind.css";
  .relative input {
    padding-right: 2rem; /* Adjust this value as needed to ensure there's enough space for the percentage symbol */
  }
  .relative span {
    pointer-events: none;
  }

  .animate-spin {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .animate-success {
    animation: success-fade 1s ease;
  }

  @keyframes success-fade {
    0% { background-color: #4ade80; }
    100% { background-color: white; }
  }
</style>